import useVisible from "../../hooks/useVisible.jsx";
import copyClipboard from "../../utils/copyClipboard";

function ExportElementsDaVinci({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible("ExportElementsDaVinciVisibility");
  const [bakeKeyDr, setBakeKeyDr] = useVisible("bakeKeyElementsDaVinci");
  const [expressionDr, setExpressionDr] = useVisible("expressionElementsDaVinci");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if(result){
        copyClipboard(result)
      }
    });
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-square-e"></i>
        </div>
        <div className="button-card__title">Export Elements DaVinci</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        {/* <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="bakeKeyDr"
            checked={bakeKeyDr}
            onChange={() => setBakeKeyDr(!bakeKeyDr)}
            className="hidden-checkbox"
          />
          <label htmlFor="bakeKeyDr">
            <span className="custom-checkbox"></span>
            <span className="label-text">Shape Pre-Comp</span>
          </label>
        </div> */}
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="expressionElementDr"
            checked={expressionDr}
            onChange={() => setExpressionDr(!expressionDr)}
            className="hidden-checkbox"
          />
          <label htmlFor="expressionElementDr">
            <span className="custom-checkbox"></span>
            <span className="label-text">Color Control</span>
          </label>
        </div>
        </div>
        <div className="render-group__tools-grid">

        <div
            onClick={() =>
              handleClick(
                "drTimeRemap",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-regular fa-arrows-rotate-reverse"></i>
            <span className="render-group__button-text">Time Remap</span>
          </div>

        <div
            onClick={() =>
              handleClick(
                "copyTransform",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-square-quarters"></i>
            <span className="render-group__button-text">Transform Layer</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "copyTextLayer",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-text-size"></i>
            <span className="render-group__button-text">Text Layer</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyShape",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-shapes"></i>
            <span className="render-group__button-text">Shape Layer</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyMask",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-vector-square"></i>
            <span className="render-group__button-text">Mask Layer</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopySolid",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-regular fa-rectangle"></i>
            <span className="render-group__button-text">Solid Layer</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyAdjustment",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-circle-half-stroke"></i>
            <span className="render-group__button-text">Adjustment Layer</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "copyTransform",
                JSON.stringify({
                  bakeKey: false,
                  type: 'effect',
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-square-quarters"></i>
            <span className="render-group__button-text">Transform Effect</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyOffset",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-bars-staggered"></i>
            <span className="render-group__button-text">Offset</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyBlur",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-circle"></i>
            <span className="render-group__button-text">Blur</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyCornerPin",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-regular fa-map-pin"></i>
            <span className="render-group__button-text">Corner Pin</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "drCopyDropShadow",
                JSON.stringify({
                  bakeKey: false,
                  colorControl: expressionDr,
                  notification: true,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-regular fa-eclipse"></i>
            <span className="render-group__button-text">Drop Shadow</span>
          </div>

        </div>
      </div>
    </>
  );
}

export default ExportElementsDaVinci;
