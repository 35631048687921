import React, { useContext, useState } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import useVisible from "../../hooks/useVisible.jsx";
import getGoogleFonts from "../../utils/buttons/getGoogleFonts";
import getFootages from "../../utils/buttons/getFootages";

function ExportMotionGroup({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible("ExportMotionGroupVisibility");
  const [preComp, setPreComp] = useVisible("preComp");
  const [timeRemap, setTimeRemap] = useVisible("timeRemapMotion");
  const [textCollapse, setTextCollapse] = useVisible("textCollapseMotion");
  const [reverseControls, setReverseControls] = useVisible("reverseControlsMotion");
  const [clone, setClone] = useVisible("disableCloneMotion");
  // const [pointText, setPointText] = useVisible("pointText");

  const [type, setType] = useState("am");
  const [format, setFormat] = useState("txt");

  const currentUser = useContext(CurrentUserContext);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-download"></i>
        </div>
        <div className="button-card__title">Export Motion</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="reverse-control-motion"
            checked={reverseControls}
            onChange={() => setReverseControls(!reverseControls)}
            className="hidden-checkbox"
          />
          <label htmlFor="reverse-control-motion">
            <span className="custom-checkbox"></span>
            <span className="label-text">Reverse Controls</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="timeRemap"
            checked={timeRemap}
            onChange={() => setTimeRemap(!timeRemap)}
            className="hidden-checkbox"
          />
          <label htmlFor="timeRemap">
            <span className="custom-checkbox"></span>
            <span className="label-text">Time Remap</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="preComp"
            checked={preComp}
            onChange={() => setPreComp(!preComp)}
            className="hidden-checkbox"
          />
          <label htmlFor="preComp">
            <span className="custom-checkbox"></span>
            <span className="label-text">Shape Pre-Comp</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="textCollapseMotion"
            checked={textCollapse}
            onChange={() => setTextCollapse(!textCollapse)}
            className="hidden-checkbox"
          />
          <label htmlFor="textCollapseMotion">
            <span className="custom-checkbox"></span>
            <span className="label-text">Collapse Text Comp</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="disableClone"
            checked={clone}
            onChange={() => setClone(!clone)}
            className="hidden-checkbox"
          />
          <label htmlFor="disableClone">
            <span className="custom-checkbox"></span>
            <span className="label-text">Disable Clone</span>
          </label>
        </div>
        {/* <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="pointText"
            checked={pointText}
            onChange={() => setPointText(!pointText)}
            className="hidden-checkbox"
          />
          <label htmlFor="pointText">
            <span className="custom-checkbox"></span>
            <span className="label-text">Convert Point Text</span>
          </label>
        </div> */}
        </div>
        <div className="render-group__tools-grid">

        <div
            onClick={() =>
              handleClick(
                "AE2FCP",
                JSON.stringify({
                  type: 'layer',
                  collapse: textCollapse,
                  timeRemap: timeRemap,
                  preComp: preComp,
                  bake:true,
                  reverseControls: reverseControls,
                  name: currentUser.login,
                  //pointText: pointText,
                  clone: !clone,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span className="render-group__button-text">Selected Layers</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "AE2FCP",
                JSON.stringify({
                  type: 'comp',
                  collapse: textCollapse,
                  timeRemap: timeRemap,
                  preComp: preComp,
                  bake:true,
                  reverseControls: reverseControls,
                  name: currentUser.login,
                  //pointText: pointText,
                  clone: !clone,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span className="render-group__button-text">Active Comp</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "AE2FCP",
                JSON.stringify({
                  type: 'selected comp',
                  collapse: textCollapse,
                  timeRemap: timeRemap,
                  preComp: preComp,
                  bake:true,
                  reverseControls: reverseControls,
                  name: currentUser.login,
                  //pointText: pointText,
                  clone: !clone,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-images"></i>
            <span className="render-group__button-text">Selected Comps</span>
          </div>

          <div
            onClick={() => handleClick('createHelpMA', JSON.stringify(type))}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-file-pdf"></i>
            <span>Help PDF</span>
          </div>
          <div
            onClick={() => getGoogleFonts(false, true, format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-brands fa-google"></i>
            <span>Google Fonts</span>
          </div>
          <div
            onClick={() => getFootages(format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Links Footages</span>
          </div>

        </div>
      </div>
    </>
  );
}

export default ExportMotionGroup;
