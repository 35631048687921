import useVisible from "../../hooks/useVisible.jsx";

function FlipValueGroup({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("FlipValueGroupVisibility");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  const buttonConfigs = [
    {
      name: "Position XY",
      iconClass: "fa-solid fa-arrows-up-down-left-right",
      argument: { positionX: true, positionY: true },
    },
    {
      name: "Position X",
      iconClass: "fa-solid fa-arrows-left-right",
      argument: { positionX: true, positionY: false},
    },
    {
      name: "Position Y",
      iconClass: "fa-solid fa-arrows-up-down",
      argument: { positionX: false, positionY: true },
    },
    {
      name: "Scale",
      iconClass: "fa-regular fa-expand",
      argument: { flipScale:true },
    },
    {
      name: "Rotation",
      iconClass: "fa-solid fa-rotate-reverse",
      argument: { flipRotation:true },
    },
  ];

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-sharp fa-light fa-arrows-repeat"></i>
        </div>
        <div className="button-card__title">Flip Value</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__tools-grid">
          {buttonConfigs.map((button) => (
            <div
              key={button.name}
              className="render-group__button"
              onClick={() => handleClick("flipValue", JSON.stringify(button.argument))}
            >
              <i className={button.iconClass}></i>
              <span className="render-group__button-text">{button.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FlipValueGroup;
