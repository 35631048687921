import React, {useEffect, useState, useContext } from 'react';
import SelectedPlaceholder from "../SelectedPlaceholder/SelectedPlaceholder.jsx";
import BackgroundColor from "../BackgroundColor/BackgroundColor.jsx";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import './EaseKey.css';

const EaseKey = () => {
  const [easeInValue, setEaseInValue] = useState(() => Number(localStorage.getItem('easeInValue')) || 50);
  const [easeValue, setEaseValue] = useState(() => Number(localStorage.getItem('easeValue')) || 50);
  const [easeOutValue, setEaseOutValue] = useState(() => Number(localStorage.getItem('easeOutValue')) || 50);
  const [data, setData] = useState(false);
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    localStorage.setItem('easeInValue', easeInValue);
    localStorage.setItem('easeValue', easeValue);
    localStorage.setItem('easeOutValue', easeOutValue);
  }, [easeInValue, easeValue, easeOutValue]);

  const handleSliderClick = (type, value) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(easeKey('${type}', ${Math.round(value)}));`, function (result) {
      // Обработка результата
    });
  };

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (result && result !== "undefined") {
        setData(result);
      }
    });
  };

  return (
    <>
    <div className="easeKey-container">
      <div className='easeKey-second'>
    <SelectedPlaceholder/>
    <BackgroundColor/>
    </div>
      <div className="easeKey-sliderContainer">
      <span className="easeKey-icon">&lt;</span>
        <input
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={easeInValue}
          onChange={(e) => setEaseInValue(e.target.value)}
          onMouseUp={() => handleSliderClick('ease in', easeInValue)}
        />
        <span className="easeKey-value">{Math.round(easeInValue)}</span>
      </div>

      <div className="easeKey-sliderContainer">
      <span className="easeKey-icon">&gt;&lt;</span>
        <input
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={easeValue}
          onChange={(e) => setEaseValue(e.target.value)}
          onMouseUp={() => handleSliderClick('ease', easeValue)}
        />
        <span className="easeKey-value">{Math.round(easeValue)}</span>
      </div>

      <div className="easeKey-sliderContainer">
      <span className="easeKey-icon">&gt;</span>
        <input
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={easeOutValue}
          onChange={(e) => setEaseOutValue(e.target.value)}
          onMouseUp={() => handleSliderClick('ease out', easeOutValue)}
        />
        <span className="easeKey-value">{Math.round(easeOutValue)}</span>
      </div>
      {currentUser.role === 'admin' && (
        <div className='easeKey-button__container'>
          <div onClick={() => handleClick("ae2EaseCopy", '')} className="easeKey-button">
            <span>Copy</span>
          </div>
          <div onClick={() => handleClick("ae2EasePaste", data)} className="easeKey-button">
            <span>Paste</span>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default EaseKey;
