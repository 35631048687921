import React, { useEffect, useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./SubscriptionCheck.css";

function SubscriptionCheck({ handleSignOut }) {
  const currentUser = useContext(CurrentUserContext);

  // Обертка для функции openWebsite
  const openWebsite = (url) => () => {
    window.cep.util.openURLInDefaultBrowser(url);
  };

  const endDates = currentUser?.subscription?.end;

  if (endDates && endDates.length > 0) {
    const lastEndDate = new Date(endDates[endDates.length - 1]);
    const now = new Date();

    if (now > lastEndDate) {
      const day = lastEndDate.getDate();
      const month = lastEndDate.getMonth() + 1;
      const year = lastEndDate.getFullYear();
      const time = `${lastEndDate.getHours()}:${lastEndDate.getMinutes()}`;

      return (
        <div className="subscription-check">
          <div className="subscription-check__message">
            Your subscription has expired on {day}/{month}/{year} at {time}
          </div>
          <div className="user-profile__request" onClick={openWebsite("https://t.me/Solomon_K1ng")}>Request a subscription</div>
          {/* <div className="user-profile__logout" onClick={handleSignOut}>Sign Out</div> */}
          <div className="subscription-reload" onClick={() => window.location.reload()}></div>
        </div>
      );
    }
  }

  if (!currentUser?.subscription || currentUser.subscription.status === false) {
    return (
      <div className="subscription-check">
        <div className="subscription-check__message">Subscription is not active</div>
        <span className="subscription-check__span">Request 2 weeks of free access</span>
        <div className="user-profile__request" onClick={openWebsite("https://t.me/Solomon_K1ng")}>Request a trial</div>
        {/* <div className="user-profile__logout" onClick={handleSignOut}>Sign Out</div> */}
        <div className="subscription-reload" onClick={() => window.location.reload()}></div>
      </div>
    );
  }

  return null;
}

export default SubscriptionCheck;
