import useVisible from "../../hooks/useVisible.jsx";

function BakePropertyPremiere({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('BakePropertyPremiereVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-regular fa-wave-sine"></i>
        <div />
      </div>
      <div className="button-card__title">Bake Property</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("ppBakeProperty", JSON.stringify({notification:true, bakeProperty:'position'}))}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-arrows-up-down-left-right"></i>
        <span>Position</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", JSON.stringify({notification:true, bakeProperty:'ds position'}))}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-arrows-up-down-left-right"></i>
        <span>Separated Position</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", JSON.stringify({notification:true, bakeProperty:'scale'}))}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-arrows-maximize"></i>
        <span>Scale</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", JSON.stringify({notification:true, bakeProperty:'rotation'}))}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-rotate-right"></i>
        <span>Rotation</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", JSON.stringify({notification:true, bakeProperty:'opacity'}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-circle-half-stroke"></i>
        <span>Opacity</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", JSON.stringify({notification:true, bakeProperty:'all'}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-bars"></i>
        <span>All</span>
      </div>
    </div>
    </>
  );
}

export default BakePropertyPremiere;
