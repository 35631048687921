import useVisible from "../../hooks/useVisible.jsx";
import React, { useState } from "react";
import "./TransitionMogrtGroup.css";

function TransitionMogrtGroup({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible(
    "TransitionMogrtGroupVisibility"
  );
  const [global, setGlobal] = useVisible("transitionGlobal");
  const [protectedAdobe, setProtectedAdobe] = useVisible("protectedAdobe");
  const [data, setData] = useState(false);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (result && result !== "undefined") {
        setData(result);
      }
    });
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-light fa-paper-plane"></i>
        </div>
        <div className="button-card__title">Transition Mogrt</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="changeName"
            checked={global}
            onChange={() => setGlobal(!global)}
            className="hidden-checkbox"
          />
          <label htmlFor="changeName">
            <span className="custom-checkbox"></span>
            <span className="label-text">Global Control</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="protectedAdobe"
            checked={protectedAdobe}
            onChange={() => setProtectedAdobe(!protectedAdobe)}
            className="hidden-checkbox"
          />
          <label htmlFor="protectedAdobe">
            <span className="custom-checkbox"></span>
            <span className="label-text">Protected Adobe</span>
          </label>
        </div>
        </div>

        <div className="transition-group__tools">
          <div
            onClick={() => handleClick("getMGTransitionPlaceholder", false)}
            className={`render-group__button ${data ? "active" : ""}`}
          >
            <i className="fa-regular fa-circle-a"></i>
            <span className="render-group__button-text">
              {data ? "Placeholder Received" : "Set Placeholder"}
            </span>
          </div>

          <div
            onClick={() => {
              handleClick(
                "transitionMogrt",
                JSON.stringify({ global: global, data: data, protected: protectedAdobe})
              );
              //setData(false);
            }}
            className={`render-group__button ${!data ? "disabled" : ""}`}
          >
            <i class="fa-light fa-square-plus"></i>
            <span className="render-group__button-text">Create</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransitionMogrtGroup;
