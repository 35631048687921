import React from "react";
import './InfoTooltip.css';
import successImage from "../../images/success.svg";
import unsuccessImage from "../../images/unsuccess.svg";
import attentionImage from "../../images/attention.svg";

function InfoTooltip({ onClose, isInfoTooltip }) {
  // Определяем изображение на основе статуса
  let imageSrc = unsuccessImage; // По умолчанию используем изображение для 'unsuccess'
  if (isInfoTooltip.status === true) {
    imageSrc = successImage;
  } else if (isInfoTooltip.status === null) { // Используем null как представление статуса "внимание"
    imageSrc = attentionImage;
  }
  // Для false используется imageSrc = unsuccessImage по умолчанию

  return (
    <div onClick={onClose} className={`infotooltip ${isInfoTooltip.isOpen ? "infotooltip_opened" : ""}`}>
      <div className="infotooltip__container">
        <button
          id="success-close-button"
          type="button"
          className="infotooltip__close-button"
          onClick={(e) => { e.stopPropagation(); onClose(); }}
        />
        <img
          className="infotooltip__image"
          src={imageSrc}
          alt=""
        />
        <h2 className="infotooltip__title">{isInfoTooltip.text}</h2>
      </div>
    </div>
  );
}

export default InfoTooltip;
