import useVisible from "../../hooks/useVisible.jsx";

function BakeExpressionGroup({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("BakeExpressionGroupVisibility");
  const [bakeColor, setBakeColor] = useVisible("bakeColor");
  const [bakeTimeRemap, setBakeTimeRemap] = useVisible("BakeExpressionTimeRemap");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-diamond"></i>
          <div />
        </div>
        <div className="button-card__title">Bake Expression</div>
        {generateButtonTags()}
      </li>
      <div className={`render-group ${isVisible ? "" : "render-group--hidden"}`}>

      <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="bakeColor"
            checked={bakeColor}
            onChange={() => setBakeColor(!bakeColor)}
            className="hidden-checkbox"
          />
          <label htmlFor="bakeColor">
            <span className="custom-checkbox"></span>
            <span className="label-text">Bake Color</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="bakeTimeRemap"
            checked={bakeTimeRemap}
            onChange={() => setBakeTimeRemap(!bakeTimeRemap)}
            className="hidden-checkbox"
          />
          <label htmlFor="bakeTimeRemap">
            <span className="custom-checkbox"></span>
            <span className="label-text">Bake TimeRemap</span>
          </label>
        </div>
        </div>
        <div className="videobolt-tools">
        <div
          onClick={() =>
            handleClick(
              "bakeExpression",
              JSON.stringify({ type: "property", notification: true })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-arrow-pointer"></i>
          <span>Selected Properties</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "bakeExpression",
              JSON.stringify({ type: "layer", bakeColor: bakeColor, bakeTimeRemap: bakeTimeRemap, notification: true })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "bakeExpression",
              JSON.stringify({ type: "comp", bakeColor: bakeColor, bakeTimeRemap: bakeTimeRemap, notification: true })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-image-polaroid"></i>
          <span>Active Comp</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "bakeExpression",
              JSON.stringify({ type: "selected comp", bakeColor: bakeColor, bakeTimeRemap: bakeTimeRemap, notification: true })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-images"></i>
          <span>Selected Comps</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "bakeExpression",
              JSON.stringify({ type: "project", bakeColor: bakeColor, bakeTimeRemap: bakeTimeRemap, notification: true })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-photo-film"></i>
          <span>Project</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "bakeExpression",
              JSON.stringify({ type: "value", bakeColor: bakeColor, bakeTimeRemap: bakeTimeRemap, notification: true })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-trash"></i>
          <span>Delete Expression</span>
        </div>
        </div>
      </div>
    </>
  );
}

export default BakeExpressionGroup;
