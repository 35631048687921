import useVisible from "../../hooks/useVisible.jsx";
import ppText from "../../utils/buttons/ppText";

function ExportElementsPremiere({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('ExportElementsPremiereVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
          onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-regular fa-square-e"></i>
        <div />
      </div>
      <div className="button-card__title">Export Elements Premiere</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("ppSortFiles", true)}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-bars-sort"></i><span>Sort Files</span>
      </div>
      <div
        onClick={() => ppText(false)}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-text"></i><span>Text Layer</span>
      </div>
      <div
        onClick={() => handleClick("ppTrackMatte", true)}
        className="resolution-tools__button"
      >
        <i class="fa-sharp fa-regular fa-object-group"></i><span>Track Matte</span>
      </div>
      <div
        onClick={() => handleClick("ppColors", true)}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-palette"></i><span>Colors Effect</span>
      </div>
      <div
        onClick={() => handleClick("ppShiftChannels", true)}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-sliders"></i><span>Shift Channels</span>
      </div>
      <div
        onClick={() => handleClick("ppLockLayer", true)}
        className="resolution-tools__button"
      >
        <i class="fa-sharp fa-regular fa-lock"></i><span>Lock Layer</span>
      </div>
    </div>
    </>
  );
}

export default ExportElementsPremiere;
