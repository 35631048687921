import React, { useContext, useState, useEffect } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./UserProfile.css";
import { formatDate, capitalizeFirstLetter } from "../../utils/utils.js";

const UserProfile = ({ isShown, onClose, handleUpdateUser, handleSignOut, lastHash }) => {
  const currentUser = useContext(CurrentUserContext);
  const { login, name, family, email, subscription } = currentUser;
  const { end } = subscription;


  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`user-profile ${isShown ? "user-profile_visible" : ""}`}
      onClick={handleBackdropClick}
    >
      <div className="user-profile__bio">
        <div className="user-profile__name">
          Welcome Back, {capitalizeFirstLetter(name)}
        </div>
        <span className="user-profile__login">
          Thank you for subscribing to AE2.
        </span>
      </div>
      <div className="user-profile__subscription">
        Access to {formatDate(end[end.length - 1])}
      </div>
      <span className="user-profile__login">
      </span>
      <div className="user-profile__logout" onClick={handleSignOut}>
        Sign Out
      </div>
    </div>
  );
};

export default UserProfile;
