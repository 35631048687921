import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./ColorsSchemes.css";
import { v4 as uuidv4 } from "uuid";
const allColors = require("nice-color-palettes");

function ColorsSchemes({onPopupStateChange, selectedTag, buttonTag, generateButtonTags}) {
  const [colors, setColors] = useState([]);
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage ? parseInt(savedPage, 10) : 0;
  });

  const [isVisible, setIsVisible] = useVisible('ColorsSchemesVisibility');
  const [ignoreBWColor, setIgnoreBWColor] = useVisible("IgnoreBWColor");

  useEffect(() => {
    const savedColors = localStorage.getItem('colorSchemes');
    if (!savedColors) {
      const colorSchemesWithId = allColors.slice(0, 100).map(scheme => ({ id: uuidv4(), colors: scheme })); // Ограничим для примера
      localStorage.setItem('colorSchemes', JSON.stringify(colorSchemesWithId));
      setColors(colorSchemesWithId);
    } else {
      setColors(JSON.parse(savedColors));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  const schemesPerPage = 5;
  const currentSchemes = colors.slice(currentPage * schemesPerPage, (currentPage + 1) * schemesPerPage);

  const handleClick = (script, argument) => {
    console.log(argument)
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  const changeColor = (color, schemeId, colorIndex) => {
    const startColor = hexToRgbArray(color);
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(
      "run($.ae.colorPicker(" + JSON.stringify(startColor) + "));",
      function (data) {
        if(data){

          if(startColor.join(',') !== data){
            let rgbArray = data.split(',').map(value => parseFloat(value));
            let newColor = rgbArrayToHex(rgbArray);

            const newColors = colors.map(scheme => {
              if (scheme.id === schemeId) {
                const newSchemeColors = [...scheme.colors];
                newSchemeColors[colorIndex] = newColor; // Используем HEX-код напрямую
                return { ...scheme, colors: newSchemeColors };
              }
              return scheme;
            });

            setColors(newColors);
            localStorage.setItem('colorSchemes', JSON.stringify(newColors));
          }
        }
      }
    );
  };

  function rgbArrayToHex(rgbArray) {
    return '#' + rgbArray.map(value => {
      let hex = Math.round(value * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  }

  const hexToRgbArray = (hex) => {
    // Убираем #, если он есть
    hex = hex.replace(/^#/, '');
    // Преобразовываем HEX в RGB
    const bigint = parseInt(hex, 16);
    const r = ((bigint >> 16) & 255) / 255;
    const g = ((bigint >> 8) & 255) / 255;
    const b = (bigint & 255) / 255;

    return [r, g, b]; // Возвращаем нормализованный массив RGB
      };

      const handleDeleteScheme = (id) => {
        const filteredColors = colors.filter(scheme => scheme.id !== id);
        setColors(filteredColors);
        localStorage.setItem('colorSchemes', JSON.stringify(filteredColors));

        // Обновляем количество страниц после удаления
        const newPageCount = Math.ceil(filteredColors.length / schemesPerPage);

        // Если после удаления текущая страница больше не существует (например, была удалена последняя схема на последней странице)
        if (currentPage >= newPageCount) {
          // Устанавливаем currentPage на последнюю существующую страницу, если это возможно, иначе на первую страницу
          const newCurrentPage = newPageCount > 0 ? newPageCount - 1 : 0;
          setCurrentPage(newCurrentPage);
          localStorage.setItem('currentPage', newCurrentPage.toString());
        }

        // Проверяем, остались ли схемы на текущей странице
        const startIndex = currentPage * schemesPerPage;
        if (!filteredColors.slice(startIndex, startIndex + schemesPerPage).length) {
          // Здесь можно добавить логику для перехода на предыдущую страницу или обновления интерфейса, чтобы отобразить сообщение о пустой странице.
          // Пример перенаправления на предыдущую страницу:
          handlePrev(); // Убедитесь, что handlePrev корректно обрабатывает случай с переходом на последнюю страницу, когда currentPage === 0
        }
      };

      const handleAddScheme = () => {
        window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
        window.jsx.evalScript(`run(getColorsScheme(${false}));`, function(data) {
          if (data) {
            const rgbaScheme = JSON.parse(data); // Предполагается, что это массив цветов в формате RGB

        // Преобразование конкретной схемы цветов в HEX, исключая альфа-канал
        const newScheme = rgbaScheme.map(color => rgbArrayToHex(color.slice(0, 3)));

        // Создаём объект схемы с уникальным идентификатором
        const newSchemeWithId = { id: uuidv4(), colors: newScheme };

        // Обновляем массив цветовых схем
        const updatedColors = [...colors, newSchemeWithId];
        setColors(updatedColors);

        // Сохраняем обновлённый массив схем в localStorage
        localStorage.setItem('colorSchemes', JSON.stringify(updatedColors));

        // Вычисляем номер страницы для новой схемы
        const newPageIndex = Math.ceil(updatedColors.length / schemesPerPage) - 1;
        setCurrentPage(newPageIndex);
        localStorage.setItem('currentPage', newPageIndex.toString());
      }
    });
      };


  const handlePrev = () => {
    const newPageIndex = currentPage === 0 ? Math.ceil(colors.length / schemesPerPage) - 1 : currentPage - 1;
    setCurrentPage(newPageIndex);
    localStorage.setItem('currentPage', newPageIndex.toString());
  };

  const handleNext = () => {
    const newPageIndex = currentPage === Math.ceil(colors.length / schemesPerPage) - 1 ? 0 : currentPage + 1;
    setCurrentPage(newPageIndex);
    localStorage.setItem('currentPage', newPageIndex.toString());
  };

  const handleRandom = () => setCurrentPage(Math.floor(Math.random() * Math.ceil(colors.length / schemesPerPage)));

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-eye-dropper"></i>
        </div>
        <div className="button-card__title">Colors Schemes</div>
        {generateButtonTags()}
      </li>
      {isVisible && (
        <div className="colors-schemes">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="ignoreBWColor"
              checked={ignoreBWColor}
              onChange={() => {
                setIgnoreBWColor(!ignoreBWColor);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="ignoreBWColor">
              <span className="custom-checkbox"></span>
              <span className="label-text">Skip B&W Color</span>
            </label>
          </div>
      {currentSchemes.map(scheme => (
        <div key={scheme.id} className="color-scheme-container">
        <div className="color-scheme">
          {scheme.colors.map((color, index) => (
            <div key={index} className="color-block" style={{ backgroundColor: color }} onClick={() => changeColor(color, scheme.id, index)}></div>
          ))}
        </div>
        <div className="color-scheme-action">
        <span className="apply-span" onClick={() => handleClick('createColorsScheme', JSON.stringify(scheme.colors.map(hexToRgbArray)))}><i class="fa-regular fa-arrow-down"></i></span>
      <span className="apply-span" onClick={() => handleClick('updateColorsScheme', JSON.stringify({colors:scheme.colors.map(hexToRgbArray), bwColor:ignoreBWColor}))}><i class="fa-regular fa-arrow-right"></i></span>
      <span className="apply-span" onClick={() => handleClick('updateColorsScheme', JSON.stringify({colors:scheme.colors.map(hexToRgbArray).sort(function() { return Math.random() - 0.5; }), bwColor:ignoreBWColor}))}><i class="fa-regular fa-shuffle"></i></span>
      <span className="apply-span" onClick={() => handleClick('updateColorsScheme', JSON.stringify({colors:scheme.colors.map(hexToRgbArray).slice().reverse(), bwColor:ignoreBWColor}))}><i class="fa-solid fa-arrow-left"></i></span>
      <span className="apply-span" onClick={() => handleDeleteScheme(scheme.id)}><i class="fa-regular fa-trash"></i></span>
          </div>
        </div>
      ))}
      <div className="scheme-navigation-btn scheme-navigation-add" onClick={handleAddScheme}><i class="fa-solid fa-plus"></i></div>
      <div className="scheme-navigation">
      <div className="scheme-navigation-btn" onClick={handlePrev}>&lt;</div>
            <div className="scheme-navigation-btn" onClick={handleRandom}><i class="fa-solid fa-rotate"></i></div>
            <div className="scheme-navigation-btn" onClick={handleNext}>&gt;</div>
      </div>
      </div>
      )}
    </>
  );
}


export default ColorsSchemes;
