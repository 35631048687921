import "./ParagraphButtons.css";

const ParagraphButtons = () => {
  const buttons = [
    {
      argument: "left",
      icon: '<i class="fa-regular fa-align-left"></i>',
    },
    {
      argument: "center",
      icon: '<i class="fa-regular fa-align-center"></i>',
    },
    {
      argument: "right",
      icon: '<i class="fa-regular fa-align-right"></i>',
    },
  ];

  function handleClick(script, argument) {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}('${argument}'));`, function (result) {
    });
  }

  return (
    <section className="paragraphs-card">
      {buttons.map((btn, index) => (
        <li
          key={index}
          className="paragraphs-card button-icon"
          onClick={() => handleClick('ae2Paragraph', btn.argument)}
        >
          <div className="button-card__paragraph">
            <div dangerouslySetInnerHTML={{ __html: btn.icon }} />
          </div>
        </li>
      ))}
    </section>
  );
};

export default ParagraphButtons;
