import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./Grid.css";

function Grid({
  onPopupStateChange,
  selectedTag,
  buttonTag,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("gridVisibility");
  const [columns, setColumns] = useState(
    localStorage.getItem("gridColumns") || ""
  );
  const [rows, setRows] = useState(localStorage.getItem("gridRows") || "");
  const [gap, setGap] = useState(localStorage.getItem("gridGap") || "");
  const [round, setRound] = useState(localStorage.getItem("gridRound") || "");
  const [autoStick, setAutoStick] = useVisible("gridAutoStick");
  const [padding, setPadding] = useVisible("gridPadding");
  const [percentValue, setPercentValue] = useVisible("gridPercentValue");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  const handleChange = (e) => {
    // Разрешаем ввод только чисел, пробелов и запятых
    const value = e.target.value;
    const regex = /^[0-9\,]*$/;

    if (value === "" || regex.test(value)) {
      setRows(value);
    }
  };

  useEffect(() => {
    localStorage.setItem("gridColumns", columns);
  }, [columns]);

  useEffect(() => {
    localStorage.setItem("gridRows", rows);
  }, [rows]);

  useEffect(() => {
    localStorage.setItem("gridGap", gap);
  }, [gap]);

  useEffect(() => {
    localStorage.setItem("gridRound", round);
  }, [round]);

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-solid fa-grid-2-plus"></i>
        </div>
        <div className="button-card__title">Grid</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="grid__input-text">
            <span className="grid__input-title">Columns</span>
            <input
              className="rename__input shape__input"
              type="number"
              value={columns}
              onChange={(e) => setColumns(e.target.value)}
            />
          </div>
          <div className="grid__input-text">
            <span className="grid__input-title">Rows</span>
            <input
              className="rename__input shape__input"
              type="text"
              value={rows}
              onChange={handleChange}
            />
          </div>
          <div className="grid__input-text">
            <span className="grid__input-title">Gap</span>
            <input
              className="rename__input shape__input"
              type="number"
              value={gap}
              onChange={(e) => setGap(e.target.value)}
            />
          </div>
          <div className="grid__input-text">
            <span className="grid__input-title">Round</span>
            <input
              className="rename__input shape__input"
              type="number"
              value={round}
              onChange={(e) => setRound(e.target.value)}
            />
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="gridPadding"
              checked={padding}
              onChange={() => setPadding(!padding)}
              className="hidden-checkbox"
            />
            <label htmlFor="gridPadding">
              <span className="custom-checkbox"></span>
              <span className="label-text">Padding</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="gridAutoStick"
              checked={autoStick}
              onChange={() => setAutoStick(!autoStick)}
              className="hidden-checkbox"
            />
            <label htmlFor="gridAutoStick">
              <span className="custom-checkbox"></span>
              <span className="label-text">Auto Stick</span>
            </label>
          </div>
        </div>
        <div className="resolution-tools">
          <div
            onClick={() =>
              handleClick(
                "createGrid",
                JSON.stringify({
                  columns: columns || 1,
                  rows: rows.split(",").map(Number),
                  gap: gap || 0,
                  round: round || 0,
                  percent: 0,
                  type: "layer",
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span>Selected Layers</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "createGrid",
                JSON.stringify({
                  columns: columns || 1,
                  rows: rows.split(",").map(Number),
                  gap: gap || 0,
                  round: round || 0,
                  padding: padding,
                  percent: 0,
                  type: "comp",
                  autoStick: autoStick
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span>Active Comp</span>
          </div>

          <div
            onClick={() => handleClick("createGridPlaceholder")}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-regular fa-images"></i>
            <span>Create Placeholder</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Grid;
