import useVisible from "../../hooks/useVisible.jsx";

function CreatePlaceholder({selectedTag, buttonTag, generateButtonTags, category}) {
  const [isVisible, setIsVisible] = useVisible('ResolutionToolsVisibility');
  let script;
  let name = 'Create Drop Zone'

  if(category === 'mg'){
    script = 'mogrtPlaceholder';
    name = 'Create Placeholder'
  }

  if(category === 'pp'){
    script = 'ppFillPlaceholder';
    name = 'Fill Placeholder'
  }

  if(category === 'am'){
    script = 'motionDropZone';
  }

  if(category === 'gt'){
    script = 'psPlaceholder';
  }

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-regular fa-image"></i>
        <div />
      </div>
      <div className="button-card__title">{name}</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick(script, JSON.stringify({auto: true, notification:true}))}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-bolt"></i>
        <span>Auto</span>
      </div>
      <div
        onClick={() => handleClick(script, JSON.stringify({notification:true}))}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-images"></i>
        <span>Selected Comps</span>
      </div>
    </div>
    </>
  );
}

export default CreatePlaceholder;
