import useVisible from "../../hooks/useVisible.jsx";

function ParallaxAnimator({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ParallaxAnimatorVisibility");
  //const [expression, setExpression] = useVisible("AutoColorsSchemeExpression");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-sharp fa-light fa-layer-group"></i>
        </div>
        <div className="button-card__title">Parallax Animator</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        {/* <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="autoColorsSchemeExpression"
              checked={expression}
              onChange={() => setExpression(!expression)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeExpression">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Expression</span>
            </label>
          </div>
        </div> */}

        <div className="videobolt-tools">

        <div
            onClick={() =>
              handleClick(
                "ae2Parallax",
                JSON.stringify({ direction: "left", notification: true })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-right"></i>
          <span>Left to Right</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "ae2Parallax",
                JSON.stringify({ direction: "top", notification: true })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-down"></i>
          <span>Top to Bottom</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "ae2Parallax",
                JSON.stringify({ direction: "right", notification: true })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-left"></i>
          <span>Right to Left</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "ae2Parallax",
                JSON.stringify({ direction: "bottom", notification: true })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-up"></i>
          <span>Bottom to Top</span>
          </div>

        </div>
      </div>
    </>
  );
}

export default ParallaxAnimator;
