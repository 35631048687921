import useVisible from "../../hooks/useVisible.jsx";

function AddControl({selectedTag, buttonTag, generateButtonTags, category}) {
  const [isVisible, setIsVisible] = useVisible(category === 'mg' ? 'AddControlMogrt' : 'AddControlMotion');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-regular fa-gamepad"></i>
        <div />
      </div>
      <div className="button-card__title">Add Control</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("mogrtControl", JSON.stringify({name: 'control', clean: false}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-plus"></i>
        <span>Add Control</span>
      </div>
      <div
        onClick={() => handleClick("mogrtControl", JSON.stringify({name: 'ignore', clean: false}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-minus"></i>
        <span>Ignore Control</span>
      </div>
      <div
        onClick={() => handleClick("mogrtControl", JSON.stringify({name: '', clean: true}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-trash"></i>
        <span>Delete Control</span>
      </div>
    </div>
    </>
  );
}

export default AddControl;
