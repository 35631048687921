import React, { useEffect, useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import mainApi from "../../utils/api.js";

function DeviceCheck({ device, setCurrentUser, setDevice }) {
  const currentUser = useContext(CurrentUserContext);

  // Определение функции checkDevice
  const checkDevice = () => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(userInfo());`, function (result) {
      if (result && result !== "undefined") {
        const userInfo = JSON.parse(result);
        setDevice({
          hostName: userInfo.hostName,
          userName: userInfo.userName,
        });
        const deviceUpdate = {
          license: {
            status: true,
            hostName: userInfo.hostName,
            userName: userInfo.userName,
          },
        };
        mainApi
          .updateUser(currentUser._id, deviceUpdate)
          .then((data) => {
            setCurrentUser(data);
            console.log("Subscription updated for user:", currentUser._id);
            // Здесь может быть код для обновления контекста, если это необходимо
          })
          .catch((error) => {
            console.error(
              "Failed to update subscription for user:",
              currentUser._id,
              error
            );
          });
      }
    });
  };

  // Вызов функции checkDevice, если статус лицензии неактивен
  if (!currentUser.license.status) {
    checkDevice();
  }else if(currentUser.license.hostName !== device.hostName){
    return (
      <div
        className="subscription-check"
        onClick={() => window.location.reload()}
      >
        <div className="subscription-check__message">
          Sign out the other device:
        </div>
        <span className="subscription-check__span">
          {currentUser.license.hostName}
        </span>
      </div>
    );
  }

  return null;
}

export default DeviceCheck;
