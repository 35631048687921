import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";

function AutoCropGroup({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("AutoCropGroupVisibility");
  const [time, setTime] = useVisible("AutoCropGroupTime");
  const [padding, setPadding] = useState(localStorage.getItem("AutoCropGroupPadding") || "");

  useEffect(() => {
    localStorage.setItem("AutoCropGroupPadding", padding);
  }, [padding]);


  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-crop-simple"></i>
        </div>
        <div className="button-card__title">Auto Crop</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <input
            className="rename__input shape__input"
            type="number"
            value={padding}
            onChange={(e) => setPadding(e.target.value)}
            placeholder="Padding"
          />
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="time"
              checked={time}
              onChange={() => {
                setTime(!time);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="time">
              <span className="custom-checkbox"></span>
              <span className="label-text">Animation</span>
            </label>
          </div>
        </div>

        <div className="videobolt-tools">
        <div
          onClick={() =>
            handleClick(
              "autoCrop",
              JSON.stringify({
                type: "layer",
                time: time,
                padding: padding || 0,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "autoCrop",
              JSON.stringify({
                type: "comp",
                time: time,
                padding: padding || 0,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-image-polaroid"></i>
          <span>Active Comp</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "autoCrop",
              JSON.stringify({
                type: "selected comp",
                time: time,
                padding: padding || 0,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-images"></i>
          <span>Selected Comp</span>
        </div>
        {/* <div
          onClick={() =>
            handleClick(
              "autoCrop",
              JSON.stringify({
                type: "project",
                time: time,
                padding: padding || 0,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-photo-film"></i>
          <span>Project</span>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default AutoCropGroup;
