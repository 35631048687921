import "./NewTemplateGroup.css";
import { useState, useEffect, useRef } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import {
  textTemplate,
  transitionTemplate,
  slideshowTemplate,
} from "../../utils/templatePreset.js";

function NewTemplateGroup({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("NewTemplateGroupVisibility");
  const [capsTemplate, setCapsTemplate] = useVisible("capsTemplate");
  const [focusedId, setFocusedId] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const [templateType, setTemplateType] = useVisible("templateType");
  const [resolutionValue, setResolutionValue] = useVisible("resolutionValue");
  const [fpsValue, setFpsValue] = useVisible("fpsValue");

  const [textTemplateState, setTextTemplateState] = useVisible("textTemplate");
  const [transitionTemplateState, setTransitionTemplateState] =
    useVisible("transitionTemplate");
  const [slideshowTemplateState, setSlideshowTemplateState] =
    useVisible("slideshowTemplate");

  const handleClick = (script) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

    let currentTemplate = textTemplateState;
    if (templateType === "Transition") {
      currentTemplate = transitionTemplateState;
    } else if (templateType === "Slideshow") {
      currentTemplate = slideshowTemplateState;
    }

    const currentValues = {
      ...currentTemplate,
      capsName: capsTemplate,
      resolution: (function () {
        switch (resolutionValue) {
          case "full_hd":
            return 1;
          case "2k":
            return 1.3333;
          case "4k":
            return 2;
          default:
            return textTemplate.resolution;
        }
      })(),
      fps: fpsValue === "30" ? 30 : 60,
    };

    window.jsx.evalScript(
      `run(${script}('app.project.rootFolder', ${JSON.stringify(
        currentValues
      )}));`,
      function (result) {}
    );
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  function EditableItem({ item, onNameChange }) {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current && item.id === focusedId) {
        inputRef.current.focus();
      }
    }, [item.name, focusedId]);

    const handleNameChange = (e) => {
      const newName = e.target.value;
      const updatedItem = { ...item, name: newName };
      onNameChange(updatedItem); // вызов handleNameChange из NewTemplateGroup
      setFocusedId(item.id);
    };

    const renderIcon = () => {
      if (item.type === "folder") {
        return <i className="fa-regular fa-folder"></i>;
      } else if (item.type === "comp") {
        return <i className="fa-regular fa-image"></i>;
      }
      return null;
    };

    return (
      <div>
        <div className="template__group">
        {renderIcon()}
        <input className="rename__input" ref={inputRef} value={item.name} onChange={handleNameChange} />
        </div>
        {item.include && Array.isArray(item.include) && (
          <div>
            {item.include.map((subItem) => (
              <EditableItem
                key={subItem.id} // использование уникального id в качестве ключа
                item={subItem}
                onNameChange={(updatedSubItem) => {
                  const newInclude = [...item.include];
                  const index = newInclude.findIndex(
                    (i) => i.id === subItem.id
                  );
                  newInclude[index] = updatedSubItem;
                  onNameChange({ ...item, include: newInclude });
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (!textTemplateState) {
      setTextTemplateState(textTemplate);
    }
    if (!transitionTemplateState) {
      setTransitionTemplateState(transitionTemplate);
    }
    if (!slideshowTemplateState) {
      setSlideshowTemplateState(slideshowTemplate);
    }
  }, []);

  useEffect(() => {
    switch (templateType) {
      case "Text":
        setCurrentTemplate(textTemplateState);
        break;
      case "Transition":
        setCurrentTemplate(transitionTemplateState);
        break;
      case "Slideshow":
        setCurrentTemplate(slideshowTemplateState);
        break;
      default:
        setCurrentTemplate(null);
    }
  }, [
    templateType,
    textTemplateState,
    transitionTemplateState,
    slideshowTemplateState,
  ]);

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <div
        className={`overlay ${isEditing ? "" : "render-group--hidden"}`}
        onClick={() => setIsEditing(false)}
      ></div>

      {isEditing && (
        <div className="template__popup">
          <div className="close-button" onClick={() => setIsEditing(false)}>X</div>
          {currentTemplate &&
            currentTemplate.items.map((item) => (
              <EditableItem
                key={item.id}
                item={item}
                focusedId={focusedId} // Передайте focusedId дочерним компонентам
                onNameChange={(newItem) => {
                  const newItems = [...currentTemplate.items];
                  const index = newItems.findIndex((i) => i.id === item.id);
                  newItems[index] = newItem;

                  const updatedTemplate = {
                    ...currentTemplate,
                    items: newItems,
                  };
                  setCurrentTemplate(updatedTemplate);

                  // Обновите соответствующее состояние и localStorage
                  switch (templateType) {
                    case "Text":
                      setTextTemplateState(updatedTemplate);
                      localStorage.setItem(
                        "textTemplate",
                        JSON.stringify(updatedTemplate)
                      );
                      break;
                    case "Transition":
                      setTransitionTemplateState(updatedTemplate);
                      localStorage.setItem(
                        "transitionTemplate",
                        JSON.stringify(updatedTemplate)
                      );
                      break;
                    case "Slideshow":
                      setSlideshowTemplateState(updatedTemplate);
                      localStorage.setItem(
                        "slideshowTemplate",
                        JSON.stringify(updatedTemplate)
                      );
                      break;
                    default:
                      break;
                  }
                }}
              />
            ))}
        </div>
      )}

      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-solid fa-plus"></i>
        </div>
        <div className="button-card__title">New Template</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">
              Template
              <div
                className="render-group__dropdown-icon"
                onClick={handleEditClick}
              >
                <i class="fa-sharp fa-solid fa-pen-to-square"></i>
              </div>
            </span>
            <select
              id="templateType"
              name="templateType"
              //className="render-group__dropdown"
              onChange={(event) => setTemplateType(event.target.value)}
              value={templateType}
            >
              <option value="Text">Text</option>
              <option value="Transition">Transition</option>
              <option value="Slideshow">Slideshow</option>
            </select>
          </div>

          {/* Первый выпадающий список */}
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Resolution</span>
            <select
              id="resolution"
              name="resolution"
              //className="render-group__dropdown"
              onChange={(event) => setResolutionValue(event.target.value)}
              value={resolutionValue}
            >
              <option value="full_hd">Full HD</option>
              <option value="2k">2K</option>
              <option value="4k">4K</option>
            </select>
          </div>

          {/* Второй выпадающий список */}
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Framerate</span>
            <select
              id="fps"
              name="fps"
              //className="render-group__dropdown"
              onChange={(event) => setFpsValue(event.target.value)}
              value={fpsValue}
            >
              <option value="30">30</option>
              <option value="60">60</option>
            </select>
          </div>
        </div>

        <div className="render-group__checkbox-group">
          {/* Ваш чекбокс */}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="capsTemplate"
              checked={capsTemplate}
              onChange={() => setCapsTemplate(!capsTemplate)}
              className="hidden-checkbox"
            />

            <label htmlFor="capsTemplate">
              <span className="custom-checkbox"></span>
              <span className="label-text">Caps Name</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("newTemplate", JSON.stringify(textTemplate))
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-folder"></i>
            <span className="render-group__button-text">Create</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewTemplateGroup;
