import mainApi from "../api.js";

function getFootages(format, type) {
  window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

  window.jsx.evalScript(`run(getFootages("${type}"));`, function (result) {
    if (result) {
      //[{"stock":"unsplash","id":"hrHmItssbeE","type":"image","url":false},{"stock":"pexels","id":"914931","type":"image","url":false}]
      const data = JSON.parse(result);
      // Проверяем, пуст ли массив footages
      const isDefaultMedia = data.footages.length === 0;
      const mediaToUse = isDefaultMedia ? [{
          url: 'https://unsplash.com'
        },
        {
          url: 'https://www.pexels.com/'
        }
      ] : data.footages;

      // Если массив не пуст, обновляем медиа ссылки, иначе используем дефолтные
      if (!isDefaultMedia) {
        mainApi.getFootages(mediaToUse)
          .then(updatedMedia => {
            saveMediaLinks(format, updatedMedia, data.path + "/Links." + format, isDefaultMedia);
          })
          .catch(error => {
            console.error('Ошибка при обновлении медиа URL:', error);
          });
      } else {
        // Сразу сохраняем дефолтные ссылки без обновления
        saveMediaLinks(format, mediaToUse, data.path + "/Links." + format, isDefaultMedia);
      }
    }
  });

  function saveMediaLinks(format, mediaArray, basePath, isDefaultMedia) {
    const content = generateContent(mediaArray, format, isDefaultMedia);
    const saveResult = window.cep.fs.writeFile(basePath, content, window.cep.encoding.UTF8);

    if (saveResult.err === 0) {
      console.log('Файл успешно сохранен:', basePath);
    } else {
      alert('Error saving file:', saveResult.err);
    }
  }

  function generateContent(mediaArray, format, isDefaultMedia) {
    let content = format === 'rtf' ?
      "{\\rtf1\\ansi\\deff0{\\fonttbl{\\f0 Arial;}}\n{\\f0 Download the free photos and videos used here:}\n" :
      "Download the free photos and videos used here:\n";

    // Initialize counters for found and not found URLs
    let foundCount = 0;
    let notFoundCount = 0;

    mediaArray.forEach((media, index) => {
      const prefix = format === 'rtf' ? "\\par " : "";
      let line;

      if (media.url === false) {
        line = "footage url not found";
        notFoundCount++; // Increment not found counter
      } else {
        line = isDefaultMedia ? `${media.url}` : `Media ${index + 1}: ${media.url}`;
        foundCount++; // Increment found counter
      }

      content += `${prefix}${line}\n`;
    });

    if (format === 'rtf') {
      content += "}";
    }

    if (!isDefaultMedia) {
      let message;

      if (notFoundCount === 0) {
        // All media URLs were found
        message = "All media found: " + foundCount;
      } else if (foundCount > 0 && notFoundCount > 0) {
        // Some media URLs were found, and some were not
        message = "Media found: " + foundCount + ". Footage URL not found: " + notFoundCount;
      } else if (foundCount === 0) {
        // No media URLs were found
        message = "No media found. Footage URL not found: " + notFoundCount;
      }
      window.jsx.evalScript("run($.ae.message(" + JSON.stringify(message) + "));", function (result) {})
    }

    // Returning the content and the counts
    return content
  }
}

export default getFootages;
