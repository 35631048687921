import {
  getGoogleFontLinks
} from "../googleFonts.js";

function getGoogleFonts(pdf, fonts, format, type) {
  window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

  if (fonts) {
    window.jsx.evalScript(`run(getFontTemplate("${type}"));`, function (result) {
      if (result) {
        const data = JSON.parse(result);
        getGoogleFontLinks(data, format).then((links) =>
          window.jsx.evalScript("run(MAfolder(" + JSON.stringify({
            fonts: links,
            helpFile: pdf
          }) + "));")
        );
      }
    });
  } else {
    window.jsx.evalScript("run(MAfolder(" + JSON.stringify({
      fonts: false,
      helpFile: pdf
    }) + "));")
  }
}

export default getGoogleFonts;
