import useVisible from "../../hooks/useVisible.jsx";

function MarkerGroup({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("MarkerGroupVisibility");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-location-pin"></i>
          <div />
        </div>
        <div className="button-card__title">Markers</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() =>
            handleClick("reverseMarker", JSON.stringify({ type: "comp" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-light fa-location-check"></i>
          <span>Comp Marker</span>
        </div>
        <div
          onClick={() =>
            handleClick("reverseMarker", JSON.stringify({ type: "layer" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-light fa-location-plus"></i>
          <span>Layer Marker</span>
        </div>
        <div
          onClick={() => handleClick("addProtected", false)}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-shield-plus"></i>
          <span>Protected Marker</span>
        </div>
      </div>
    </>
  );
}

export default MarkerGroup;
