import {
  chatWithGPT4
} from "../chatGpt.js";

function kanyeWest(){

  window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

  window.jsx.evalScript("run(postGpt());", function (result) {
    chatWithGPT4(result.split("\r\n"))
      .then((string) =>
      window.jsx.evalScript("run(getGpt(" + JSON.stringify(string) + "));")
      )
      .catch((error) => alert(error));
  });
}

export default kanyeWest;
