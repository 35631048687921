import React from 'react';
import './BackgroundColor.css';

const BackgroundColor = () => {
  const handleClick = (color) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    const colorArgument = JSON.stringify(color);
    window.jsx.evalScript(`run(bgColor(${colorArgument}));`, function (result) {
      // Обработка результата, если необходимо
    });
  };

  return (
    <div className="background__color-container">
      <div className="background__color-square black" onClick={() => handleClick([0,0,0])}></div>
      <div className="background__color-square gray" onClick={() => handleClick([0.5,0.5,0.5])}></div>
      <div className="background__color-square white" onClick={() => handleClick([1,1,1])}></div>
    </div>
  );
};

export default BackgroundColor;
