const apiKey = "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";

async function chatWithGPT4(prompts) {
  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const processPrompt = async (prompt, temperature, topP) => {
    const response = await fetch(
      "https://api.openai.com/v1/engines/text-davinci-002/completions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: prompt,
          max_tokens: 100,
          n: 1,
          stop: null,
          temperature: temperature,
          top_p: topP,
        }),
      }
    );

    const data = await response.json();
    return data.choices[0].text.trim();
  };

  const checkAndFetchUnique = async (prompt, previousResponses) => {
    const temperature = randomNumber(0.5, 1.5); // случайная температура от 0.5 до 1.5
    const topP = randomNumber(0.7, 1.0); // случайное значение top_p от 0.7 до 1.0
    let newResponse = await processPrompt(prompt, temperature, topP);
    let attempts = 0;

    while (previousResponses.includes(newResponse) && attempts < 5) {
      const newTemperature = randomNumber(0.5, 1.5);
      const newTopP = randomNumber(0.7, 1.0);
      newResponse = await processPrompt(prompt, newTemperature, newTopP);
      attempts++;
    }

    return newResponse;
  };

  const results = [];
  for (const prompt of prompts) {
    const uniqueResponse = await checkAndFetchUnique(prompt, results);
    results.push(uniqueResponse);
  }

  return results;
}

export { chatWithGPT4 };
