import useVisible from "../../hooks/useVisible.jsx";
import React, { useState, useEffect } from "react";
import "./ExpressionControls.css";

function ExpressionControls({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ExpressionControlsVisibility");
  const [layerID, setLayerID] = useVisible("expressionControlID");
  const [rename, setRename] = useVisible("expressionControlRename");
  const [compName, setCompName] = useVisible("expressionControlCompName");
  //const [reverseExpression, setReverseExpression] = useVisible("reverseExpressionExpressionControls");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (result && result !== "undefined" && script === "$.ae.layerID") {
        result = result === "false" ? (result = false) : JSON.parse(result);
        setLayerID(result.id);
        setCompName(result.compName);
      }
    });
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-sliders-simple"></i>
          <div />
        </div>
        <div className="button-card__title">Expression Controls</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="expControlRename"
              checked={rename}
              onChange={() => setRename(!rename)}
              className="hidden-checkbox"
            />
            <label htmlFor="expControlRename">
              <span className="custom-checkbox"></span>
              <span className="label-text">Rename Control</span>
            </label>
          </div>
        </div>
        <div className="set-button">
          <div
            onClick={() => handleClick("$.ae.layerID", false)}
            className={`render-group__button ${layerID ? "set-button-active exp" : ""}`}
          >
            {layerID ? (
              <div>
                {compName}
              </div>
            ) : (
              <>
                <i className="fa-regular fa-circle-a"></i>
                <span className="render-group__button-text">
                  Set Control Layer
                </span>
              </>
            )}
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Point3D Control",
                  layerID: layerID,
                  axis: false,
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-circle-dot"></i>
            <span>3D Point Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Point Control",
                  layerID: layerID,
                  axis: false,
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-circle-dot"></i>
            <span>Point Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Angle Control",
                  layerID: layerID,
                  axis: false,
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-rotate-right"></i>
            <span>Angle Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Checkbox Control",
                  layerID: layerID,
                  axis: false,
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-square-check"></i>
            <span>Checkbox Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Color Control",
                  layerID: layerID,
                  axis: false,
                  expression: true,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-fill"></i>
            <span>Color Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionAutoColors",
                JSON.stringify({
                  type: "ADBE Color Control",
                  layerID: layerID,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-swatchbook"></i>
            <span>Selected Colors</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionAutoTypeColors",
                JSON.stringify({
                  type: "ADBE Color Control",
                  layerID: layerID,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-palette"></i>
            <span>Match Name Colors</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "Original Logo Colors",
                  layerID: layerID,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-eye-dropper"></i>
            <span>Logo Colors</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "Dropdown Menu Control",
                  layerID: layerID,
                  axis: false,
                  expression: true,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-list-dropdown"></i>
            <span>Dropdown Menu Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Slider Control",
                  layerID: layerID,
                  axis: "all",
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-sliders-simple"></i>
            <span>Slider Control</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Slider Control",
                  layerID: layerID,
                  axis: "x",
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-sliders-simple"></i>
            <span>Slider Control X</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "expressionControls",
                JSON.stringify({
                  type: "ADBE Slider Control",
                  layerID: layerID,
                  axis: "y",
                  expression: true,
                  rename: rename,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-sliders-simple"></i>
            <span>Slider Control Y</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpressionControls;
