import useVisible from "../../hooks/useVisible.jsx";

function ReverseKeyMotion({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ReverseKeyMotionVisibility");
  const [bakeExpression, setBakeExpression] = useVisible("bakeExpressionMotion");
  const [trim, setTrim] = useVisible("trimCompMotion");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-light fa-arrows-rotate-reverse"></i>
        </div>
        <div className="button-card__title">Reverse Key</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="bakeExpressionMotion"
            checked={bakeExpression}
            onChange={() => setBakeExpression(!bakeExpression)}
            className="hidden-checkbox"
          />
          <label htmlFor="bakeExpressionMotion">
            <span className="custom-checkbox"></span>
            <span className="label-text">Bake Expression</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="trimMotion"
            checked={trim}
            onChange={() => setTrim(!trim)}
            className="hidden-checkbox"
          />
          <label htmlFor="trimMotion">
            <span className="custom-checkbox"></span>
            <span className="label-text">Trim Comp</span>
          </label>
        </div>
        </div>
        <div className="videobolt-tools">
        <div
            onClick={() =>
              handleClick(
                "reverseKeyToMotion",
                JSON.stringify({ type: "layer", bake: bakeExpression, trim: trim, currentTime: true, notification: true })
              )
            }
            className="resolution-tools__button"
          >
          <i class="fa-light fa-layer-group"></i>
          <span className="render-group__button-text">Selected Layer</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "reverseKeyToMotion",
                JSON.stringify({ type: "comp", bake: bakeExpression, trim: trim, currentTime: false, notification: true })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span className="render-group__button-text">Active Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "reverseKeyToMotion",
                JSON.stringify({ type: "selected comp", bake: bakeExpression, trim: trim, currentTime: false, notification: true })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span className="render-group__button-text">Selected Comps</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReverseKeyMotion;
