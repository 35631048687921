import React, { useState, useEffect, useContext } from "react";
import mainApi from "../../utils/api.js";
import "./TelegramLink.css";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const TelegramLink = ({ setIsTelegramLinked, handleUpdateUser, handleSignOut }) => {
  const currentUser = useContext(CurrentUserContext);
  // Состояние для управления режимом редактирования
  const [isEditing, setIsEditing] = useState(false);
  // Состояние для хранения текущего вводимого значения
  const [inputValue, setInputValue] = useState("");
  // Состояние для текста, отображаемого перед инпутом
  const [displayText, setDisplayText] = useState("Telegram @Username");

  // Инициализация и обновление на основе currentUser
  useEffect(() => {
    const username = currentUser?.telegram?.username;
    if (username) {
      setInputValue(username);
      setDisplayText(username);
    } else {
      setInputValue("");
      setDisplayText("Telegram @Username");
    }
  }, [currentUser]);

  useEffect(() => {
    const checkTelegramLink = () => {
      const jwt = localStorage.getItem("jwt");
      if (jwt && inputValue) {
        mainApi
          .getUserInfo()
          .then((data) => {
            console.log(data);
            if (data.telegram.isLinked) {
              setIsTelegramLinked(true);
              clearInterval(intervalId);
            }
          })
          .catch((err) =>
            console.error("Ошибка при получении данных пользователя", err)
          );
      }
    };

    let intervalId = setInterval(checkTelegramLink, 5000);
    return () => clearInterval(intervalId);
  }, [inputValue, setIsTelegramLinked]);

  const openWebsite = (url) => () => {
    window.cep.util.openURLInDefaultBrowser(url);
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    setDisplayText(inputValue || "Telegram @Username"); // Обновляем отображаемый текст при потере фокуса
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Используем регулярное выражение для удаления всех '@' из ввода
    const newValue = value.replace(/@/g, "");
    setInputValue(value);

    handleUpdateUser(currentUser._id, { telegram: { username: newValue } });
  };

  return (
    <div className="telegram-link-container">
      <span className="telegram-link__span">Enter your telegram @Username</span>
        <input
          autoFocus
          type="text"
          value={inputValue}
          className="telegram-link-input"
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder={displayText}
        />
      <div
        className={`telegram-link__request ${
          displayText !== "Telegram @Username" ? "active" : "inactive"
        }`}
        onClick={openWebsite("https://t.me/ae2extension_bot")}
      >
        Connect to Telegram Bot
      </div>
      <div className="user-profile__logout" onClick={handleSignOut}>
      Sign Out / Выйти
      </div>
    </div>
  );
};

export default TelegramLink;
