import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";

function FollowLayer({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("FollowLayerVisibility");
  const [fadeDelay, setFadeDelay] = useVisible("FollowLayerFadeDelay");
  const [delay, setDelay] = useState(localStorage.getItem("FollowLayerDelay") || "");

  useEffect(() => {
    localStorage.setItem("FollowLayerDelay", delay);
  }, [delay]);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-link"></i>
        </div>
        <div className="button-card__title">Follow Layer</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <input
            className="rename__input shape__input"
            type="number"
            value={delay}
            onChange={(e) => setDelay(e.target.value)}
            placeholder="Delay"
          />
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="fadeDelay"
              checked={fadeDelay}
              onChange={() => {
                setFadeDelay(!fadeDelay);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="fadeDelay">
              <span className="custom-checkbox"></span>
              <span className="label-text">Fade Delay</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() => {
              handleClick(
                "followLayer",
                JSON.stringify({
                  delay:delay,
                  fadeDelay: fadeDelay
                })
              );
            }}
            className="render-group__button"
          >
            <i class="fa-regular fa-link"></i>
            <span className="render-group__button-text">Create Follow</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default FollowLayer;
