import useVisible from "../../hooks/useVisible.jsx";

function ColorControls({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('ColorControlsVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
          onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-solid fa-swatchbook"></i>
        <div />
      </div>
      <div className="button-card__title">Color Controls</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("ae2ppColorControls", JSON.stringify({type:'layer'}))}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-fill-drip"></i><span>Selected Layer</span>
      </div>
      <div
        onClick={() => handleClick("ae2ppColorControls", JSON.stringify({type:'selected comp'}))}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-images"></i><span>Selected Comp</span>
      </div>
      <div
        onClick={() => handleClick("ae2ppColorControls", JSON.stringify({matte:true}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-circle-three-quarters-stroke"></i><span>Create Matte</span>
      </div>
      <div
        onClick={() => handleClick("ppGradientColor", true)}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-swatchbook"></i><span>Gradient Controls</span>
      </div>
    </div>
    </>
  );
}

export default ColorControls;
