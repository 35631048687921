function copyClipboard(filePath) {
  // Чтение файла с помощью CEP API
  let fileContent = window.cep.fs.readFile(filePath, window.cep.encoding.UTF8);

  if (fileContent.err !== 0) {
    alert("Error while reading file: " + fileContent.err);
    return;
  }

  // Создаем временный элемент для копирования данных в буфер
  let textArea = document.createElement("textarea");
  textArea.value = fileContent.data; // данные из файла
  document.body.appendChild(textArea);
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    let msg = successful ? 'успешно скопировано!' : 'ошибка при копировании.';
    console.log(msg);
  } catch (err) {
    console.error('Ошибка при копировании текста в буфер обмена', err);
  }

  // Удаляем временный элемент
  document.body.removeChild(textArea);

  window.cep.fs.deleteFile(filePath)
}

export default copyClipboard;
