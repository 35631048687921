import useVisible from "../../hooks/useVisible.jsx";
import React, { useState } from "react";
import getGoogleFonts from "../../utils/buttons/getGoogleFonts";
import getFootages from "../../utils/buttons/getFootages";
import copyClipboard from "../../utils/copyClipboard";

function ExportDavinciGroup({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible("ExportDavinciGroupVisibility");
  const [bakeKeyDr, setBakeKeyDr] = useVisible("bakeKeyDr");
  const [expressionDr, setExpressionDr] = useVisible("expressionDavinci");
  const [save, setSave] = useVisible("saveDavinci");

  const [type, setType] = useState("drm");
  const [format, setFormat] = useState("txt");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if(script === 'exportDrMacro' && result){
        copyClipboard(result)
      };
    });
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-sharp fa-regular fa-square-d"></i>
        </div>
        <div className="button-card__title">Export Macro</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        {/* <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="bakeKeyDr"
            checked={bakeKeyDr}
            onChange={() => setBakeKeyDr(!bakeKeyDr)}
            className="hidden-checkbox"
          />
          <label htmlFor="bakeKeyDr">
            <span className="custom-checkbox"></span>
            <span className="label-text">Shape Pre-Comp</span>
          </label>
        </div> */}
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="expressionDr"
            checked={expressionDr}
            onChange={() => setExpressionDr(!expressionDr)}
            className="hidden-checkbox"
          />
          <label htmlFor="expressionDr">
            <span className="custom-checkbox"></span>
            <span className="label-text">Color Control</span>
          </label>
        </div>
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="saveDR"
            checked={save}
            onChange={() => setSave(!save)}
            className="hidden-checkbox"
          />
          <label htmlFor="saveDR">
            <span className="custom-checkbox"></span>
            <span className="label-text">Auto Save</span>
          </label>
        </div>
        </div>
        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "exportDrMacro",
                JSON.stringify({
                  bakeKey: true,
                  colorControl: expressionDr,
                  save: save
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-square-arrow-up-right"></i>
            <span className="render-group__button-text">Active Comp</span>
          </div>
          <div
            onClick={() => handleClick('createHelpMA', JSON.stringify(type))}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-file-pdf"></i>
            <span>Help PDF</span>
          </div>
          <div
            onClick={() => getGoogleFonts(false, true, format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-brands fa-google"></i>
            <span>Google Fonts</span>
          </div>
          <div
            onClick={() => getFootages(format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Links Footages</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportDavinciGroup;
