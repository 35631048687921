import JSZip from 'jszip';

async function zipTemplate(data) {
  console.log('Creating Zip...');

  const createZip = async (data) => {
    const zip = new JSZip();
    const { name, folder, files } = data;

    for (let filePath of files) {
      const fileContent = window.cep.fs.readFile(filePath, window.cep.encoding.Base64);
      if (fileContent.err === 0) {
        const decodedData = base64ToArrayBuffer(fileContent.data);
        const relativePath = filePath.substring(folder.length + 1); // +1 для удаления лишнего слэша
        zip.file(relativePath, decodedData, { binary: true });
      } else {
        alert("Error while reading file: " + filePath);
        return; // Добавлен ранний выход в случае ошибки чтения файла
      }
    }

    // Ожидаем завершения генерации ZIP-архива
    const content = await zip.generateAsync({ type: "base64" });
    const savePath = folder + `/${name}.zip`;
    const result = window.cep.fs.writeFile(savePath, content, window.cep.encoding.Base64);
    if (result.err === 0) {
      console.log("Zip file saved successfully!");
    } else {
      throw new Error("Error saving file: " + result.err); // Выбрасываем ошибку, если сохранение не удалось
    }
  };

  // Ожидаем завершения создания и сохранения ZIP-архива
  await createZip(data).catch(error => {
    alert(error.message); // Обрабатываем возможную ошибку
  });

  window.jsx.evalScript("run($.ae.message(" + JSON.stringify('Zip File Completed') + "));", function (result) {})

  console.log('Zip creation process completed.');
}

function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

// Экспортируем функцию, чтобы использовать ее в другом месте.
export default zipTemplate;
