import { useState, useCallback, useEffect } from 'react';
import isEmail from 'validator/es/lib/isEmail';

export default function useFormWithValidation() {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const setValue = (field, value) => {
    setValues(prevState => ({...prevState, [field]: value }));
  };

  useEffect(() => {
    const savedValues = JSON.parse(localStorage.getItem("formValues"));
    if (savedValues) {
        setValues(savedValues);
    }
  }, []);

  const handleChange = (e) => {
    const input = e.target;
    const { value, name } = input;

    // Стандартная валидация для имени
    if (name === 'name' && input.validity.patternMismatch) {
      input.setCustomValidity('The name can only contain Cyrillic or Latin, a space or a hyphen.')
    } else if (name === 'email') {
      // Валидация для email
      if (!isEmail(value)) {
        input.setCustomValidity('Invalid email address.');
      } else {
        input.setCustomValidity('');
      }
    } else if (name === 'telegram') {
      // Валидация для имени пользователя Telegram
      const telegramPattern = /^[A-Za-z0-9_]+$/; // Или добавьте @? в начале, если хотите разрешить @
      if (!telegramPattern.test(value)) {
        input.setCustomValidity('Telegram username can only include letters, numbers, and underscores.');
      } else {
        input.setCustomValidity('');
      }
    } else {
      // Сброс пользовательской валидации для всех остальных случаев
      input.setCustomValidity('');
    }

    setValues(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevState => ({ ...prevState, [name]: input.validationMessage }));
    setIsValid(input.closest('form').checkValidity());
  };

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [setValues, setErrors, setIsValid]
  );

  return { values, handleChange, resetForm, errors, isValid, setIsValid, setValue };
}
