import useVisible from "../../hooks/useVisible.jsx";

function FillPlaceholders({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('FillPlaceholdersVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-folder-image fa-fw"></i>
          <div />
        </div>
        <div className="button-card__title">Fill Placeholders</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
      <div
        onClick={() => handleClick("fillPlaceholders", JSON.stringify({sort:false, type:'project'}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-arrow-down-to-line"></i>
        <span>Index</span>
      </div>
      <div
        onClick={() => handleClick("fillPlaceholders", JSON.stringify({sort:"reverse", type:'project'}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-arrow-down-to-line fa-flip-vertical"></i>
        <span>Reverse</span>
      </div>
      <div
        onClick={() => handleClick("fillPlaceholders", JSON.stringify({sort:"random", type:'project'}))}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-shuffle"></i>
        <span>Random</span>
      </div>
      <div
        onClick={() => handleClick("fillPlaceholders", JSON.stringify({sort:"random", type:'layer'}))}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-layer-group"></i>
        <span>Selected Layers</span>
      </div>
      <div
        onClick={() => handleClick("fillPlaceholders", JSON.stringify({sort:"random", type:'selected comp'}))}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-images"></i>
        <span>Selected Comps</span>
      </div>
      </div>
    </>
  );
}

export default FillPlaceholders;
