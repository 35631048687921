import useVisible from "../../hooks/useVisible.jsx";

function Collapse({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('CollapseVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-compress"></i>
          <div />
        </div>
        <div className="button-card__title">Collapse</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() => handleClick("collapseOn", JSON.stringify({type:"layer", collapse: true, undo:true}))}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-layer-plus"></i>
          <span>Layers Enabled Collapse</span>
        </div>
        <div
          onClick={() => handleClick("collapseOn", JSON.stringify({type:"layer", collapse: false, undo:true}))}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-layer-minus"></i>
          <span>Layers Disable Collapse</span>
        </div>
        <div
          onClick={() => handleClick("collapseOn", JSON.stringify({type:"project", collapse: true, undo:true}))}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-square-e"></i>
          <span>Text Enabled Collapse</span>
        </div>
        <div
          onClick={() => handleClick("collapseOn", JSON.stringify({type:"project", collapse: false, undo:true}))}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-square-d"></i>
          <span>Text Disable Collapse</span>
        </div>
      </div>
    </>
  );
}

export default Collapse;
