import React from 'react';
import './SelectedPlaceholder.css';

const SelectedPlaceholder = () => {
  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  return (
    <div className="placeholder-container">
      <div className="placeholder-icon" onClick={() => handleClick("selectedPlaceholders", JSON.stringify({type:"text"}))}>
      <i class="fa-sharp fa-solid fa-text"></i>
      </div>
      <div className="placeholder-icon" onClick={() => handleClick("selectedPlaceholders", JSON.stringify({type:"media"}))}>
      <i class="fa-light fa-image"></i>
      </div>
      <div className="placeholder-icon" onClick={() => handleClick("selectedPlaceholders", JSON.stringify({type:"color"}))}>
      <i class="fa-regular fa-fill-drip"></i>
      </div>
    </div>
  );
};

export default SelectedPlaceholder;
